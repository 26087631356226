import React, { useState, useEffect } from 'react';
import {
  BookingPolicyModal,
  Policy,
} from '@wix/bookings-checkout-common-components';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { BookingsGroup } from '../../../../../../types';
import { CancellationModalDataHooks } from '../../../../../../components/MyBookings/Widget/datahooks';
import { classes } from './PolicyModal.st.css';

export interface BookingPolicyModalContentProps {
  group: BookingsGroup;
  onClose: () => void;
}

// TODO: Remove and replace with api
const dummyData: Policy[] = [
  {
    policyId: '1234',
    serviceNames: 'Hair Styling, highlights',
    policyDescription:
      "Clients can reschedule or cancel hair appointments at no charge with at least 24 hours' notice. However, cancellations made within 24 hours of the scheduled appointment time may incur a cancellation fee equivalent to 50% of the service cost. No-shows without prior notice will be subject to a fee equivalent to the full service cost.",
  },
  {
    policyId: '2345',
    serviceNames: 'Manicure',
    policyDescription:
      "For nail appointments, clients are encouraged to provide 48 hours' notice for any cancellations or rescheduling. Cancellations within 24 to 48 hours may be subject to a fee of 30% of the service cost, while cancellations within 24 hours or no-shows may result in a fee of 50% of the service cost.",
  },
];

export const BookingPolicyModalContent: React.FC<
  BookingPolicyModalContentProps
> = ({ group, onClose }) => {
  const [policies, setPolicies] = useState<Policy[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    // TODO: call to action
    setTimeout(() => {
      setPolicies(dummyData);
    }, 2000);
  }, []);

  const handleCloseButtonClick = () => {
    onClose();
  };

  return (
    <div className={classes.root}>
      <BookingPolicyModal
        dataHook={CancellationModalDataHooks.BookingPolicy}
        className={classes.modal}
        modalTitle={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.title',
          {
            numOfPolicies: policies.length,
          },
        )}
        isOpen
        isLoading={!policies.length}
        policies={policies}
        onClose={handleCloseButtonClick}
        onPrimaryButtonClick={handleCloseButtonClick}
        primaryButtonText={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.label',
        )}
      />
    </div>
  );
};
